<template>
  <div v-if="$store.getters.ready">
    <Header nav="project" :group="$route.params.group" :project="$route.params.project" active="labels" />
    <div class="content-wrapper">
      <main class="main-wrapper clearfix">
        <div class="container">

          <div class="row page-title clearfix">
            <div class="page-title-left">
              <h5 class="page-title-heading"><div class="breadcrumbs"><router-link :to="`/${project.group.path}`" class="no-link">{{ project.group.name }}</router-link> &bull; <router-link :to="`/${project.group.path}/${project.path}`" class="no-link">{{ project.name }}</router-link></div> Labels</h5>
            </div>
            <div class="page-title-right" v-if="$auth.profile.site_admin">
              <button type="button" class="btn btn-primary btn-rounded" @click="editLabel()"><i class="fal fa-pencil mr-2"></i>New Label</button>
            </div>
          </div>

          <div class="widget-list">

            <div v-for="(label) in labels" :key="label.id" class="widget-holder">
              <div class="widget-bg">
                <div class="widget-body">
                  <div class="row">
                    <div class="col-sm-4 col-md-3">
                      <label-badge :color="label.color" :text="label.text_color" :label="label.name" />
                    </div>
                    <div class="col-md-6 d-none d-md-block">{{ label.description }}</div>
                    <div class="col-sm-7 d-md-none pt-2 pt-sm-0" v-if="$auth.profile.site_admin">{{ label.description }}</div>
                    <div class="col-sm-8 d-md-none pt-2 pt-sm-0" v-else>{{ label.description }}</div>
                    <div class="col-md-2 d-none d-md-block" v-if="$auth.profile.site_admin"><span v-if="label.open_issues_count > 0">{{ label.open_issues_count }} open issue<span v-if="label.open_issues_count !== 1">s</span></span></div>
                    <div class="col-md-3 d-none d-md-block" v-else><span v-if="label.open_issues_count > 0">{{ label.open_issues_count }} open issue<span v-if="label.open_issues_count !== 1">s</span></span></div>
                    <div class="col-sm-1 text-right" v-if="$auth.profile.site_admin">
                      <b-dropdown toggle-class="p-0" variant="link" menu-class="dropdown-card w-dropdown-card-small mt-2" right>
                        <template v-slot:button-content><i class="fal fa-lg fa-ellipsis-v text-muted"></i></template>
                        <div class="card">
                          <header class="card-header d-flex justify-content-between">
                            <i class="fal fa-cog text-primary" aria-hidden="true"></i>
                            <span class="heading-font-family flex-1 text-center fw-400">Settings</span>
                          </header>
                          <div class="card-body list-unstyled dropdown-list">
                            <button type="button" class="dropdown-item" @click="editLabel(label.id)">Edit</button>
                            <div class="dropdown-divider"></div>
                            <button type="button" class="dropdown-item text-danger" @click="deleteLabel(label.id)"><i class="fal fa-times mr-2"></i>Delete</button>
                          </div>
                        </div>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <b-modal id="labelmodal" title="Label" centered v-if="$auth.profile.site_admin">
              <div class="form-group">
                <label class="col-form-label">Name</label>
                <input type="text" class="form-control" v-model="labelSettings.name" maxlength="64">
              </div>
              <div class="form-group">
                <label class="col-form-label">Description</label>
                <input type="text" class="form-control" v-model="labelSettings.description" maxlength="255">
              </div>
              <label class="col-form-label">Color</label><br>
              <span v-for="(color, index) in colors" :key="color.color">
                <button type="button" class="btn m-1" :style="`background: #${color.color}; width: 40px; height: 40px;`" @click="setColor(color)"><i v-if="color.color === labelSettings.color" class="fal fa-check-circle fa-lg" :style="`color: #${color.text}`"></i></button><br v-if="index === 7 || index === 15">
              </span>
              <template v-slot:modal-footer>
                <button type="button" class="btn btn-primary btn-rounded mr-auto" @click="saveLabel()">Save Label</button>
              </template>
            </b-modal>

          </div>

        </div>
      </main>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { ModalPlugin, DropdownPlugin } from 'bootstrap-vue';
import Header from '@/components/AccountHeader.vue';
import LabelBadge from '@/components/LabelBadge.vue';
import toast from '@/modules/toast';

Vue.use(ModalPlugin);
Vue.use(DropdownPlugin);

export default {
  data() {
    return {
      project: {},
      labels: [],
      labelSettings: {
        id: undefined,
        name: '',
        description: '',
        color: '',
        text_color: '',
      },
      colors: [
        { text: 'ffffff', color: 'B60812' },
        { text: 'ffffff', color: 'D5411D' },
        { text: '000000', color: 'FAC92F' },
        { text: 'ffffff', color: '198926' },
        { text: 'ffffff', color: '126879' },
        { text: 'ffffff', color: '2679D9' },
        { text: 'ffffff', color: '0D56C9' },
        { text: 'ffffff', color: '542AE4' },
        { text: 'ffffff', color: 'E99497' },
        { text: '000000', color: 'F8D0C5' },
        { text: '000000', color: 'FEF2C1' },
        { text: '000000', color: 'C0E1C9' },
        { text: '000000', color: 'C0D9DD' },
        { text: '000000', color: 'C7DDF4' },
        { text: '000000', color: 'BFD4F1' },
        { text: '000000', color: 'D4C6F9' },
        { text: 'ffffff', color: 'D13D49' },
        { text: 'ffffff', color: '38d57a' },
        { text: 'ffffff', color: '5EBEFF' },
        { text: '000000', color: 'D2DAE1' },
      ],
    };
  },
  methods: {
    editLabel(id) {
      this.labelSettings = JSON.parse(JSON.stringify(this.getLabel(id)));
      this.$bvModal.show('labelmodal');
    },
    setColor(c) {
      this.labelSettings.color = c.color;
      this.labelSettings.text_color = c.text;
    },
    saveLabel() {
      let resource;
      if (this.labelSettings.id) {
        resource = this.$api.put(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels/${this.labelSettings.id}`, this.labelSettings);
      } else {
        resource = this.$api.post(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels`, this.labelSettings);
      }
      resource
        .then(() => {
          this.$bvModal.hide('labelmodal');
          this.updateLabels();
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    deleteLabel(id) {
      if (!id) {
        return;
      }
      this.$api.delete(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels/${id}`)
        .then(() => {
          this.$cache.api.remove(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels`);
          for (let i = 0; i < this.labels.length; i += 1) {
            if (this.labels[i].id === id) {
              this.labels.splice(i, 1);
            }
          }
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
    getLabel(id) {
      if (id) {
        for (let i = 0; i < this.labels.length; i += 1) {
          if (this.labels[i].id === id) {
            return this.labels[i];
          }
        }
      }
      return { id: undefined, name: '', description: '', color: '' };
    },
    updateLabels() {
      this.$cache.api.update.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}/labels`)
        .then((res) => {
          this.labels = res.data;
        })
        .catch((e) => {
          toast.danger(this, e.response.data.message);
        });
    },
  },
  async mounted() {
    try {
      this.project = (await this.$cache.api.get(`/projects/${this.$route.params.group}%2F${this.$route.params.project}`)).data;
      this.updateLabels(); // update cache, so issue counts are accurate
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  components: {
    Header,
    LabelBadge,
  },
};
</script>
